//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {},
  data() {
    return {
      loading: false,
      ruleForm: {
        teamNum: null,
        inviterWord: null,
      },
      rules: {
        teamNum: [{ required: true, message: "请输入团队号", trigger: "blur" }],
      },
    };
  },
  created() {
    let token = this.$xStorage.getItem("token");
    let role = this.$xStorage.getItem("user-role");
    if (token && role.role == "用户") {
      return;
    } else {
      this.$router.push({
        path: "/",
        query: {
          invitUrl: this.$route.fullPath,
        },
      });
    }
  },
  mounted() {
    const code = this.$route.query.teamCode;
    if (code) {
      this.ruleForm.teamNum = code;
    }
  },
  methods: {
    /**
     * 确认
     */
    onConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          const data = {
            teamCode: this.ruleForm.teamNum,
            inviterId: this.$route.query.userID
              ? this.$route.query.userID
              : null,
            inviterWord: this.ruleForm.inviterWord,
          };
          this.$http
            .post("/Teams/InvitedOrApply/ApplyTeam.ashx", data)
            .then((resp) => {
              if (resp.res == 0) {
                this.$message({
                  showClose: true,
                  message: "申请发送成功！",
                  type: "success",
                });
                this.$router.push("/profile");
              }
            })
            .finally(() => (this.loading = false));
        } else {
          return;
        }
      });
    },
  },
};
